import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [ 'review', 'hideButton'];

  show() {
    this.element.style.minHeight = '450px';
    this.element.style.height = 'auto';
    this.reviewTarget.classList.remove('expandable');
    this.hideButtonTarget.classList.remove('hidden');
  }

  hide() {
    this.reviewTarget.classList.add('expandable');
    this.hideButtonTarget.classList.add('hidden');
    console.log("CLICKED")
  }
}