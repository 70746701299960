import { Controller } from "stimulus";

export default class extends Controller {
    static targets = ["segment"];

    initialize() {
        this.groupIds = this.data.get("groupIds");
        this.code = this.data.get('code');
        this.schoolName = this.data.get('schoolName');
        this.schoolId= this.data.get('schoolId');
        var segment = this.segmentTarget;
        $.ajax({
            url: `/zoom/site/groups?group_ids=${this.groupIds}&school_name=${this.schoolName}&school_id=${this.schoolId}`,
            type: 'GET',
            beforeSend: function(e) {
                $(segment).addClass("loading");
            },
            success: function(data) {
                $(segment).removeClass("loading");
                $(segment).append(data);
            },
            error: function (e){
                $(segment).removeClass("loading");
                $(segment).append(`<div class="ui error message">${e.responseJSON.errors}</div>`)
            }
        });
    }
}