// app/javascript/controllers/glide_controller.js
import { Controller } from "stimulus"

export default class extends Controller {
  connect() {
    window.addEventListener('scroll', this.addShadow.bind(this))
  }

  disconnect() {
    window.removeEventListener('scroll', this.addShadow.bind(this))
  }

  addShadow() {
    if (window.scrollY > 0) {
      this.element.classList.add('box-shadow')
    } else {
      this.element.classList.remove('box-shadow')
    }
  }
}