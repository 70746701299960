import { Controller } from "stimulus";

export default class extends Controller {
    initialize() {
        this.schoolId = this.data.get("schoolId");
        this.code = this.data.get("code");
    }

    list() {
        $.get(`/zoom/site/subjects?school_id=${this.schoolId}`, (response) => {
            console.log(response);
        });
    }
}