// app/javascript/controllers/glide_controller.js
import { Controller } from "stimulus"
import Glide from '@glidejs/glide'

export default class extends Controller {
  static values = { perView: Number, autoplay: Number, breakPoints: String, backgroundColor: String }

  connect() {
    console.log(this.backgroundColorValue)
    const defaultBreakpoints = { 1440: { perView: 4 }, 1250: { perView: 4 }, 1039: { perView: 3 }, 780: { perView: 2}, 575: {perView: 1.5}  }
    const breakPoints = this.breakPointsValue ? JSON.parse(this.breakPointsValue) : defaultBreakpoints;
    const glide = new Glide(this.element, {
      type: 'carousel',
      startAt: 0,      
      perView: this.perViewValue,
      autoplay: this.autoplayValue,
      breakpoints: breakPoints,
      animationDuration: 800
    });

    glide.mount();
  }
}