import { Controller } from "stimulus";

export default class extends Controller {
    static targets = ["existLink", "startLink", "copyStartLink", "newLink", "error", "copyLink", "startLoader", "loader"]

    initialize()  {
        this.groupId = this.data.get("groupId");
        this.code = this.data.get('code');
        this.subject_id = this.data.get("subjectId");
        this.subjectName = this.data.get("subjectName");
        this.groupName = this.data.get("groupName");
        this.schoolName = this.data.get("schoolName");
        this.schoolId = this.data.get("schoolId");
        var existLink = this.existLinkTarget;
        var errorTarget = this.errorTarget;
        var copyLink = this.copyLinkTarget;
        var copyStartLink = this.copyStartLinkTarget;
        var startLink = this.startLinkTarget;
        var loader = this.loaderTarget;
        var startLoader = this.startLoaderTarget;
        $(existLink).hide();
        $(startLink).hide();
        $(copyLink).hide();
        $(copyStartLink).hide();
        $.ajax({
            url: `/zoom/zoom?group_id=${this.groupId}&code=${this.code}&subject_id=${this.subject_id}`,
            type: 'GET',
            success: (response, status, xhr) => {
                $(loader).removeClass("active");
                $(startLoader).removeClass("active");
                $(startLink).show();
                $(startLink).addClass("activeLink");
                $(existLink).show();
                $(existLink).addClass("activeLink");
                // $(copyLink).hide();
                // $(copyStartLink).show();
                $(existLink).attr("href",response.info["join_url"]);
                $(startLink).attr("href",response.info["start_url"]);
                startLink.innerText = response.info["start_url_truncate"];
                existLink.innerText = response.info["join_url_truncate"];
            },
            error: (response, status, xhr) => {
                $(startLoader).removeClass("active");
                $(loader).removeClass("active");
                $(existLink).show();
                $(startLink).show();
                $(startLink).addClass("disabledLink");
                $(existLink).addClass("disabledLink");
                $(existLink).removeClass("loading");
                $(existLink).attr("href", "");
                $(startLink).attr("href", "");
                $(errorTarget).append(`<div class="ui top attached warning message">${response.responseJSON.errors}</div>`)
            }
        });
    }

    createNewRoom()  {
        var newLink = this.newLinkTarget;
        var existLink = this.existLinkTarget;
        var errorTarget = this.errorTarget;
        var copyLink = this.copyLinkTarget;
        var copyStartLink = this.copyStartLinkTarget;
        var startLink = this.startLinkTarget;
        var loader = this.loaderTarget;
        var startLoader = this.startLoaderTarget;
        $.ajax({
            url:  `/zoom/zoom?group_id=${this.groupId}&code=${this.code}&subject_id=${this.subject_id}&school_name=${this.schoolName}&school_id=${this.schoolId}&group_name=${this.groupName}&subjectNam=${this.subjectName}`,
            type: "POST",
            beforeSend: () => {
                $(newLink).addClass("loading");
                $(startLoader).addClass("active");
                $(loader).addClass("active");
                $(existLink).hide();
                // $(copyStartLink).hide();
                $(startLink).hide();
                // $(copyLink).hide();
            },
            success: (response, status, xhr) => {
                $(loader).removeClass("active");
                $(startLoader).removeClass("active");
                $(existLink).addClass("activeLink");
                $(existLink).removeClass("disabledLink");
                // $(copyStartLink).show();
                $(startLink).addClass("activeLink");
                $(startLink).removeClass("disabledLink");
                $(newLink).removeClass("loading");
                // $(copyLink).show();
                $(existLink).show();
                $(startLink).show();
                $(existLink).attr("href",response.info["join_url"]);
                existLink.innerText = response.info["join_url_truncate"];
                $(startLink).attr("href",response.info["start_url"]);
                startLink.innerText = response.info["start_url_truncate"];
                $(errorTarget).remove();
            },
            error: (response, status, xhr) => {
                $(existLink).show();
                $(startLoader).removeClass("active");
                $(startLink).show();
                $(copyLink).hide();
                $(copyStartLink).hide();
                $(newLink).removeClass("loading");
                errorTarget.innerHTML = `<div class="ui top attached error message">${response.responseJSON.errors}</div>`
            }
        });
    }

    copyLink() {
        var $temp = $("<input>");
        $("body").append($temp);
        var existLink = this.existLinkTarget;
        var copyLinkTarget = this.copyLinkTarget;

        $temp.val($(existLink).attr('href')).select();
        document.execCommand("copy");
        $temp.remove();
        $(copyLinkTarget)
            .popup({
                content : 'Скопировано',
                on: 'manual',
                exclusive: true
            })
            .popup('show')
        ;
    }

    copyStartLink() {
        var $temp = $("<input>");
        $("body").append($temp);
        var existLink = this.startLinkTarget;
        var copyLinkTarget = this.copyStartLinkTarget;

        $temp.val($(existLink).attr('href')).select();
        document.execCommand("copy");
        $temp.remove();
        $(copyLinkTarget)
            .popup({
                content : 'Скопировано',
                on: 'manual',
                exclusive: true
            })
            .popup('show')
        ;
    }
}